// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle = function<TFunction extends (...args: any[]) => void>(
  limitMs: number,
  func: TFunction
): TFunction {
  const last = {
    timeoutId: -Infinity,
    timestamp: -Infinity
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(this: any, ...args) {
    clearTimeout(last.timeoutId);

    last.timeoutId = setTimeout(() => {
      if (Date.now() - last.timestamp >= limitMs) {
        func.apply(this, args);
        last.timestamp = Date.now();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, limitMs - (Date.now() - last.timestamp)) as any;
  } as TFunction;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <TFunction extends (...args: any[]) => void>(
  delay: number,
  fn: TFunction
) => {
  let timeoutID: number | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((...args: any[]) => {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn(...args), delay);
  }) as TFunction;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dynamicDebounce = <TFunction extends (...args: any[]) => void>(
  fn: TFunction
) => {
  let timeoutID: number | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((delay, ...args: any[]) => {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn(...args), delay);
  }) as (delay: number, ...args: Parameters<TFunction>) => void;
};
