import '../styles/home-page.scss';
import { Carousel } from 'antd';

import { useIntl } from 'gatsby-plugin-intl';
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby-plugin-intl';

import CtaSection from '../components/cta-section';
import Layout from '../components/layout';
import Particles from '../components/particles';
import SEO from '../components/seo';
import { homePageThunderboltParticleBase64 } from '../config/particlesBase64';
import { generateUniqId } from '../utils/generate-uniq-id';

import SvgLeftCircleOutlined from '../images/svgs/left-circle-arrow.svg';
import SvgRightCircleOutlined from '../images/svgs/right-circle-arrow.svg';
import SvgAboutInnovationsOrientedIcon from '../images/svgs/home-page/about-innovations-oriented.svg';
import TilesList, { TilesListProps } from '../components/tiles-list';
import HwwProcessStep1Icon from '../images/svgs/how-we-work/process-step-1-icon.svg';
import HwwProcessStep2Icon from '../images/svgs/how-we-work/process-step-2-icon.svg';
import HwwProcessStep3Icon from '../images/svgs/how-we-work/process-step-3-icon.svg';
import HwwProcessStep4Icon from '../images/svgs/how-we-work/process-step-4-icon.svg';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import SectionPreviewPdf from '../components/section-preview-pdf';
import { FENCES_FILE_PATH_IN_STORAGE } from '../config/storage';

const CAROUSEL_SLIDES_COUNT = 3;
const CAROUSEL_AUTOPLAY_SPEED = 2500;

const CAROUSEL_TILES = [
  {
    id: 'sliderImage_1',
    titleId: 'home.CAROUSEL_TILE_1_TITLE',
    descriptionId: 'home.CAROUSEL_TILE_1_DESCRIPTION'
  },
  {
    id: 'sliderImage_2',
    titleId: 'home.CAROUSEL_TILE_2_TITLE'
  },
  {
    id: 'sliderImage_3',
    titleId: 'home.CAROUSEL_TILE_3_TITLE'
  }
];

const HOW_WE_WORK_ITEMS: TilesListProps['items'] = [
  {
    id: generateUniqId(),
    titleLocalizationKey: 'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_1',
    descriptionLocalizationKey:
      'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_1_DESCRIPTION',
    customNode: (
      <div className="tile-item-custom-node">
        <HwwProcessStep1Icon />
      </div>
    )
  },
  {
    id: generateUniqId(),
    titleLocalizationKey: 'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_2',
    descriptionLocalizationKey:
      'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_2_DESCRIPTION',
    customNode: (
      <div className="tile-item-custom-node">
        <HwwProcessStep2Icon />
      </div>
    )
  },
  {
    id: generateUniqId(),
    titleLocalizationKey: 'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_3',
    descriptionLocalizationKey:
      'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_3_DESCRIPTION',
    customNode: (
      <div className="tile-item-custom-node">
        <HwwProcessStep3Icon />
      </div>
    )
  },
  {
    id: generateUniqId(),
    titleLocalizationKey: 'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_4',
    descriptionLocalizationKey:
      'home.HOME_PAGE_HOW_WE_WORK_SECTION_PROCESS_STEP_4_DESCRIPTION',
    customNode: (
      <div className="tile-item-custom-node">
        <HwwProcessStep4Icon />
      </div>
    )
  }
];

const ABOUT_US_ITEMS: TilesListProps['items'] = [
  {
    id: generateUniqId(),
    icon: <SvgAboutInnovationsOrientedIcon />,
    titleLocalizationKey: 'about.QUALITY_TITLE',
    descriptionLocalizationKey: 'about.QUALITY_TILE_DESCRIPTION'
  },
  {
    id: generateUniqId(),
    icon: <SvgAboutInnovationsOrientedIcon />,
    titleLocalizationKey: 'about.WARRANTY_TITLE',
    descriptionLocalizationKey: 'about.WARRANTY_DESCRIPTION'
  },
  {
    id: generateUniqId(),
    icon: <SvgAboutInnovationsOrientedIcon />,
    titleLocalizationKey: 'about.SUPPORT_TITLE',
    descriptionLocalizationKey: 'about.SUPPORT_DESCRIPTION'
  },
  {
    id: generateUniqId(),
    icon: <SvgAboutInnovationsOrientedIcon />,
    titleLocalizationKey: 'about.TEAM_SPIRIT_TILE_TITLE',
    descriptionLocalizationKey: 'about.TEAM_SPIRIT_TILE_DESCRIPTION'
  }
];

const HomePage: React.FC = () => {
  const intl = useIntl();

  const [slide, setSlide] = useState(0);
  const carouselRef = useRef<any>(null);

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      carousel.goTo(slide);
    }
  }, [carouselRef, slide]);

  const images = useStaticQuery(graphql`
    query {
      containersImage: file(
        relativePath: { eq: "containers-page/containers.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fencesImage: file(relativePath: { eq: "fences-page/fences.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      windowsImage: file(
        relativePath: { eq: "windows-page/windows-hero.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      winterGardenImage: file(
        relativePath: { eq: "winter-garden-page/winter-garden-hero.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carportsImage: file(
        relativePath: { eq: "carports-page/carports-hero.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doorsImage: file(relativePath: { eq: "doors-page/doors-hero.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderImage_1: file(relativePath: { eq: "home-page/slider-1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderImage_2: file(relativePath: { eq: "home-page/slider-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderImage_3: file(relativePath: { eq: "home-page/slider-3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const SERVICES_TILES = [
    {
      id: generateUniqId(),
      titleId: 'services.TILES_FENCES_TITLE',
      descriptionId: 'services.TILES_FENCES_DESCRIPTION',
      backgroundImg: images?.fencesImage?.childImageSharp?.fluid,
      link: '/fences'
    }
  ];

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'home.SEO_TITLE' })} />

      <main id="home-page">
        <section className="super-hero-section">
          <Particles
            imageSrc={homePageThunderboltParticleBase64}
            height={650}
          />
          <div className="container hero-headings section-headings-container">
            <h1>
              {intl.formatMessage({
                id: 'home.HOME_PAGE_HERO_SECTION_TITLE'
              })}
            </h1>
            <p className="text-align-center">
              <strong>
                {intl.formatMessage({
                  id: 'home.HOME_PAGE_HERO_SECTION_SUB_TITLE'
                })}
              </strong>
            </p>
          </div>
        </section>

        <section className="section-container section-light">
          <div className="container centered flex-d-column section-headings-container">
            <p className="text-uppercase text-small">
              {intl.formatMessage({
                id: 'navigation.PAGE_ABOUT_TITLE'
              })}
            </p>
            <h3 className="section-description">
              {intl.formatMessage({
                id: 'home.HOME_PAGE_ABOUT_SECTION_ABOUT_TITLE'
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: 'home.HOME_PAGE_ABOUT_SECTION_ABOUT_SUB_TITLE'
              })}
            </p>
          </div>

          <div className="container">
            <TilesList items={ABOUT_US_ITEMS} itemsPerRowOnDesktop={4} />
          </div>
        </section>

        <section className="section-container">
          <div className="container" data-aos="fade-up">
            <div className="centered">
              <div className="flex-d-column centered section-headings-container">
                <p className="text-uppercase text-small">
                  {intl.formatMessage({ id: 'navigation.PAGE_SERVICES_TITLE' })}
                </p>
                <h3 className="section-description">
                  {intl.formatMessage({
                    id: 'home.HOME_PAGE_ABOUT_SECTION_SERVICES_TITLE'
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: 'home.HOME_PAGE_ABOUT_SECTION_SERVICES_DESCRIPTION'
                  })}
                </p>
              </div>
            </div>

            <div className="tiles" data-aos="fade-up">
              {SERVICES_TILES.map(tile => (
                <div className="tile" key={tile.id}>
                  <Img fluid={tile.backgroundImg} className="tile-image" />
                  <div className="tile-content">
                    <h3>{intl.formatMessage({ id: tile.titleId })}</h3>
                    <p>{intl.formatMessage({ id: tile.descriptionId })}</p>
                  </div>
                  <Link to={tile.link} />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="section-light">
          <div className="container">
            <div className="row">
              <div className="col">
                <SectionPreviewPdf
                  filePathInStorage={FENCES_FILE_PATH_IN_STORAGE}
                  buttonLabel={intl.formatMessage({ id: 'common.SEE_CATALOG' })}
                  title={intl.formatMessage({ id: 'fences.SECTION_PDF_TITLE' })}
                  heading={intl.formatMessage({
                    id: 'fences.SECTION_PDF_HEADING'
                  })}
                  paragraph={intl.formatMessage({
                    id: 'fences.SECTION_PDF_PARAGRAPH'
                  })}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-container how-we-work-section">
          <div className="container">
            <div className="centered">
              <div className="flex-d-column centered section-headings-container">
                <p className="text-uppercase text-small">
                  {intl.formatMessage({
                    id: 'navigation.PAGE_HOW_WE_WORK_TITLE'
                  })}
                </p>
                <h3 className="section-description">
                  {intl.formatMessage({
                    id: 'home.HOME_PAGE_ABOUT_SECTION_HOW_WE_WORK_TITLE'
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: 'home.HOME_PAGE_ABOUT_SECTION_HOW_WE_WORK_SUB_TITLE'
                  })}
                </p>
              </div>
            </div>

            <TilesList
              items={HOW_WE_WORK_ITEMS}
              itemsPerRowOnDesktop={4}
              alignment="left"
            />
          </div>
        </section>

        <CtaSection
          extraContent={
            <div className="carousel-container" data-aos="fade-up">
              <Carousel
                ref={carouselRef}
                afterChange={index => setSlide(index)}
              >
                {Array.from({ length: CAROUSEL_SLIDES_COUNT }).map(
                  (_, index) => {
                    const { id, titleId, descriptionId } = CAROUSEL_TILES[
                      index
                    ];

                    return (
                      <div key={id} style={{ position: 'relative' }}>
                        <Img fluid={images?.[id]?.childImageSharp?.fluid} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            padding: 40,
                            color: '#fff'
                          }}
                        >
                          <p>{intl.formatMessage({ id: titleId })}</p>
                          {descriptionId && (
                            <p>{intl.formatMessage({ id: descriptionId })}</p>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </Carousel>

              <div
                className="carousel-button carousel-button-left"
                onClick={() =>
                  setSlide(current =>
                    current === 0 ? CAROUSEL_SLIDES_COUNT - 1 : current - 1
                  )
                }
              >
                <SvgLeftCircleOutlined />
              </div>

              <div
                className="carousel-button carousel-button-right"
                onClick={() =>
                  setSlide(current =>
                    current === CAROUSEL_SLIDES_COUNT - 1 ? 0 : current + 1
                  )
                }
              >
                <SvgRightCircleOutlined />
              </div>
            </div>
          }
        />
      </main>
    </Layout>
  );
};

export default HomePage;
